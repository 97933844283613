import { computed, ref } from '@nuxtjs/composition-api'

const isExpanded = ref(true)

export const useSidebar = () => {
  const setExpanded = (value: boolean) => {
    isExpanded.value = value
  }

  return {
    isExpanded: computed(() => isExpanded.value),
    setExpanded
  }
}
