import { useStore, computed } from '@nuxtjs/composition-api'

export const useCalendarStore = () => {
  const store = useStore()

  const startDate = computed<Date>(() => store.getters['calendar/getStartDate'])
  const setStartDate = (startDate: Date) => {
    store.commit('calendar/setStartDate', startDate)
  }

  return {
    startDate,
    setStartDate
  }
}
