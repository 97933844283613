module.exports = {
  title: 'Przychody i wydatki z gabinetu/praktyki',
  income: 'Dochody',
  expenses: 'Wydatki',
  save: 'Zapisz',
  changeMeetingsPaymentStatus: 'Zmień status płatności',
  paymentMethod: 'Metoda płatności',
  paymentDate: 'Data płatności',
  paymentStatus: 'Płatność',
  markTheFollowingMeeting: 'Następujące elementy zostaną zmienione',
  moreEntries: 'kolejne elementy',
  patient: 'pacjent',
  visibleOnlyForAdminOrFinanse:
    'Ten widok jest widoczny wyłącznie dla administratora lub osoby z uprawnieniami finansowymi',
  actions: {
    delete: 'Usuń wydatki',
    markAsPaid: 'Oznacz usługi jako zapłacone'
  },
  table: {
    all: '@:employees.all',
    generatePdfReport: 'Wygeneruj raport pdf',
    generateCsvReport: 'Wygeneruj raport csv',
    generateExcelReport: 'Wygeneruj raport excel',
    successfullyDeleted: 'Pomyślnie usunięto!',
    empty: 'Brak rekordów, dodaj je za pomocą przycisku Dodaj',
    selected: 'wybrane',
    clear: 'Wyczyść',
    total: 'ŁĄCZNIE w wybranym zakresie',
    pageTotal: 'ŁĄCZNIE na stronie',
    apply: 'Zastosuj',
    deleteSelected: 'Usuń wybrane',
    serviceName: 'Nazwa usługi',
    serviceAvgPrice: 'Średnia cena usługi',
    timeDate: 'Data i czas',
    addExpense: 'Dodaj wydatek',
    expensesName: 'Nazwa',
    date: 'Data',
    addedBy: 'Dodane przez',
    note: 'Notatka',
    repeat: 'Powtórzenia',
    patient: 'Pacjent',
    addIncome: 'Dodaj przychód',
    therapist: 'Terapeuta',
    amount: 'Kwota',
    amountPaid: 'Zapłacono',
    status: 'Status',
    paid: 'Opłacone',
    payment: 'Płatność',
    unpaid: 'Do opłacenia',
    paymentNotRequired: 'Niewymagane',
    confirmDeletion: 'Potwierdź usunięcie',
    confirmCaption: 'Czy na pewno chcesz usunąć ten wydatek?',
    confirmCaptionIncome: 'Czy na pewno chcesz usunąć ten przychód?',
    currentPageReportTemplate: 'Pokazuje {first} do {last} z {totalRecords}',
    changeMeetingsPaymentStatus: 'Zmień status płatności spotkań',
    week1: '1 tydzień',
    week2: '2 tygodnie',
    month1: '1 miesiąc',
    filters: {
      name: 'Wybierz usługi',
      time: 'Selektor czasu',
      expensesName: 'Nazwa wydatku',
      addedBy: 'Dodane przez selektora',
      patient: 'Wybierz pacjenta',
      therapist: 'Wybierz terapeutę',
      amount: 'Selektor kwoty',
      status: 'Wybierz status',
      paymentMethod: 'Wybierz sposób płatności'
    },
    payments: {
      paid: '@:sessions.payments.paid',
      paymentNotRequired: '@:sessions.payments.paymentNotRequired',
      awaitingPayment: '@:sessions.payments.awaitingPayment',
      other: '@:sessions.payments.other',
      przelewy24: '@:sessions.payments.przelewy24'
    },
    paymentDate: 'Data płatności',
    clearFilters: 'Wyczyść filtry'
  },
  expense: {
    addNewExpense: 'Dodaj nowy wydatek',
    addNewIncome: 'Dodaj nowy przychód',
    editIncome: 'Edytuj przychód',
    editExpense: 'Edytuj wydatek',
    payment: 'Płatność',
    expenseName: 'Nazwa wydatku',
    serviceName: 'Nazwa',
    amount: 'Kwota',
    expenseDate: 'Data wydatku',
    incomeDate: 'Data przychodu',
    paymentDate: 'Data płatności',
    paid: 'Opłacone',
    paymentMethod: 'Metoda płatności',
    patientSelect: 'Wybierz pacjenta',
    therapistSelect: 'Wybierz terapeutę',
    statusSelect: 'Wybierz status',
    repeatCaption1: 'To jest wydatek',
    repeatCaption2: 'pojedynczy',
    repeatCaption3: 'cykliczny, który się powtarza',
    expenseRepeatsEvery: 'Wydatek powtarza się co',
    week1: '@:income.table.week1',
    week2: '@:income.table.week2',
    month1: '@:income.table.month1',
    note: 'Notatka',
    save: 'Zapisz',
    successfullyAdded: 'Pomyślnie dodano!'
  }
}
