import { ActiveFavoriteTherapistsState } from '../types/activeFavoriteTherapists'

export const state = (): ActiveFavoriteTherapistsState => ({
  therapistIds: []
})

export const mutations = {
  setActiveFavoriteTherapists(
    state: ActiveFavoriteTherapistsState,
    therapistIds: number[]
  ) {
    state.therapistIds = therapistIds
  }
}

export const getters = {
  getActiveFavoriteTherapists: (state: ActiveFavoriteTherapistsState) =>
    state.therapistIds
}
