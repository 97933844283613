module.exports = {
  couple: 'para',
  therapist: 'Terapeuta',
  room: 'Pokój',
  paidOn: 'Opłacone dnia',
  unpaid: 'Nieopłacone',
  hasBeen: 'Zostało',
  locked: 'zmienione',
  archived: 'zarchiwizowane',
  shifted: 'przesunięte | i przesunięte',
  hasNote: 'Posiada notatkę',
  hasPatientNote: 'Posiada notatkę pacjenta',
  hasSecretNote: 'Posiada sekretną notatkę',
  noP24Config: 'Nie została zakończona procedura rejestracji do przelewy24',
  paymentNotRequired: 'Płatność niewymagana',
  deletedTherapist:
    "Ten pracownik został usunięty lub nie posiada już wymaganej roli: 'Pracownik'."
}
