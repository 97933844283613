module.exports = {
  title: 'Ośrodek / gabinet',
  address: 'Adres gabinetu',
  contactDetails: 'Dane kontaktowe',
  invoiceData: 'Dane firmy',
  invoiceConfig: 'Ustawienia faktur',
  name: 'Nazwa gabinetu',
  shortName: 'Nazwa skrótowa',
  rooms: 'Pokoje',
  exportData: 'Eksport danych',
  formSteps: {
    name: 'Jak się nazywa Twój gabinet?',
    address: 'Jaki jest adres Twojego gabinetu?',
    phoneAndEmail: 'Jaki jest numer telefonu i adres email Twojego gabinetu?'
  },
  select: {
    youAreViewing: 'Teraz przeglądasz',
    clickToSwitch: 'Kliknij, by przełączyć na',
    clickToSwitchPractice: 'Inne gabinety (kliknij, aby wybrać)',
    clickToAddNewPractice: 'Kliknij aby dodać nowy gabinet zgodnie z cennikiem',
    addNewPractice: 'Dodaj nowy gabinet'
  },
  create: {
    buttons: {
      practiceAddress: 'Adres gabinetu',
      contactDetails: 'Dane kontaktowe',
      nextStep: 'Następny krok'
    }
  },
  logo: {
    image: 'Logo',
    uploadOrDrag: 'Wybierz lub przeciągnij plik'
  },
  branches: {
    all: 'Wszystkie oddziały'
  },
  settings: {
    title: 'Ustawienia gabinetu',
    notesPerReception: 'Ukrycie notatek dla recepcji',
    notesPerReceptionDescription:
      'Włączenie tej opcji spowoduje ukrycie notatek ze spotkań dla pracowników z rolą recepcji',
    servicesPerTherapist: 'Widoczność usług dla terapeutów',
    servicesPerTherapistDescription:
      'Włączenie widoczności usług dla terapeutów spowoduje, że terapeuci zobaczą tylko te usługi, do których zostali przypisani. Pracownicy posiadający uprawnienia administratora lub pracownicy recepcji zawsze będą mieli dostęp do wszystkich usług.',
    onlyAdminReception:
      'Tylko pracownicy z uprawnieniami administratora lub recepcji mogą zobaczyć tę stronę',
    eventsPerTherapist: 'Nie pokazuj spotkań wszystkich terapeutów',
    eventsPerTherapistDescription:
      'Po włączeniu tej opcji terapeuta zobaczy w kalendarzu tylko swoje spotkania, bez widoku na spotkania innych pacjentów. Pracownicy posiadający uprawnienia administratora lub pracownicy recepcji zawsze będą mieli dostęp do wszystkich spotkań',

    calendarVisibility: 'Widoczność w kalendarzu',
    employeeDataVisibility: 'Widoczność danych pracowników',

    hidePersonalEmployeeData: "Ukryj dane osobowe w tabeli 'Pracownicy'",
    hidePersonalEmployeeDataDescription:
      "Po włączeniu tej opcji pracownicy nie będą mieli dostępu do danych osobowych innych pracowników w tabeli 'Pracownicy'. Zobaczą jedynie podstawowe informacje, np. imię i nazwisko (jeśli ma to zastosowanie)."
  },
  invoice: {
    taxNo: '@:invoices.sidebar.preview.taxNo',
    bankAccount: '@:invoices.sidebar.preview.bankAccount',
    bank: '@:invoices.sidebar.preview.bank',
    save: '@:common.save',
    companyName: '@:invoices.sidebar.preview.companyName',
    companyStreet: 'Ulica',
    postCode: 'Kod pocztowy',
    city: 'Miasto',
    country: 'Państwo',
    address: '@:invoices.sidebar.preview.address',
    apiKey: 'Klucz API',
    contractor: 'Firma',
    privatePerson: 'Osoba prywatna',
    invoicesActive: 'Faktury włączone',
    invoicesInactive: 'Faktury wyłączone',
    apiToken: 'Klucz API',
    apiTokenDescription: 'Klucz API znajdziesz w Fakturowni.',
    subdomain: 'Adres podstrony w Fakturowni',
    subDomainDescriptions: {
      part1: 'Przykładowo dla adresu',
      part2: 'https://',
      part3: 'twoja-podstrona.',
      part4: 'fakturownia.pl',
      part5: 'wpisz',
      part6: 'twoja-podstrona'
    },
    deleteLinkWithFakturownia: 'Usuń połącznie z fakturownią ',
    clickToFind: 'Kliknij tutaj aby zobaczyć jak go znaleźć.',
    fullName: 'Imię i nazwisko',
    email: 'Email'
  },
  exportDataOptions: {
    exportMeetings: {
      title: 'Eksportuj dane spotkań',
      description:
        'Kliknij tutaj, aby pobrać kompletne dane dotyczące wszystkich spotkań. Możesz również wybrać przedział dat dla spotkań, które Cię interesują.',
      selectRange: 'Wybierz przedział dat',
      dateRangePrompt:
        'Wybierz przedział dat lub pozostaw pole puste, aby pobrać wszystkie spotkania.',
      btnLabel: 'Pobierz spotkania'
    },
    exportPatients: {
      title: 'Eksportuj dane pacjentów',
      description:
        'Kliknij tutaj, aby pobrać kompletne dane dotyczące wszystkich pacjentów.',
      btnLabel: 'Pobierz pacjentów'
    },
    exportTherapists: {
      title: 'Eksportuj dane terapeutów',
      description:
        'Kliknij tutaj, aby pobrać kompletne dane dotyczące terapeutów.',
      btnLabel: 'Pobierz terapeutów'
    }
  }
}
