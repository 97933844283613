const invoices = require('./invoices')
const actions = require('./actions')
const common = require('./common')
const employees = require('./employees')
const groups = require('./groups')
const groupMembers = require('./groupMembers')
const patients = require('./patients')
const practice = require('./practice')
const rooms = require('./rooms')
const services = require('./services')
const sessions = require('./sessions')
const breadcrumb = require('./breadcrumb')
const meetingHistoryTable = require('./meetingHistoryTable')
const detailCards = require('./detailCards')
const toasts = require('./toasts')
const print = require('./print')
const couples = require('./couples')
const reports = require('./reports')
const income = require('./income')
const tooltip = require('./tooltip')
const placeholders = require('./placeholders')
const detailData = require('./detailData')
const calendar = require('./calendar')
const gdpr = require('./gdpr')
const sms = require('./sms')
const registration = require('./registration')
const worktime = require('./worktime')
const availableSlotsSearch = require('./availableSlotsSearch')
const registrationP24 = require('./registrationP24')
const registrationPayments = require('./registrationPayments')
const registrationPrzelewy24Config = require('./registrationPrzelewy24Config')
const reservations = require('./reservations')
const announcements = require('./announcements')
const email = require('./email')
const notifications = require('./notifications')

module.exports = {
  common,
  practice,
  employees,
  groups,
  groupMembers,
  patients,
  services,
  rooms,
  sessions,
  breadcrumb,
  actions,
  meetingHistoryTable,
  availableSlotsSearch,
  detailCards,
  toasts,
  print,
  couples,
  reports,
  income,
  tooltip,
  placeholders,
  detailData,
  invoices,
  registrationPayments,
  registrationPrzelewy24Config,
  registrationP24,
  calendar,
  gdpr,
  sms,
  registration,
  worktime,
  reservations,
  announcements,
  email,
  notifications,
  welcome: 'Witaj w DobrymGabinecie!',
  table: {
    currentPageReportTemplate:
      'Aktualnie wyświetlam {first} do {last} z {totalRecords}'
  },
  choosePractice: {
    choosePracticeToJoin:
      'Witaj w DobrymGabinecie! Aby rozpocząć wybierz jeden z dostępnych gabinetów/ośrodków:',
    practicesFor: 'Gabinety użytkownika',
    wantTo: 'Chcesz ',
    startYourOwnPractice: 'zarejestrować swój gabinet?',
    createOwnPractice: 'Dodaj swój gabinet',
    notSeeingPractice: 'Nie widzisz swojego gabinetu?',
    tryDifferentEmail: 'Spróbuj innego adresu e-mail',
    noPractices: 'Nie posiadasz dostępnych gabinetów.',
    scheduleMeeting: 'Umów się na spotkanie'
  },
  createPractice: {
    hello: 'Witaj w DobrymGabinecie!',
    practiceDetails:
      'Aby rozpocząć podaj proszę danego Twojego gabinetu lub ośrodka. ',
    congratulations: 'Gratulacje!',
    createdPractice: 'Udało Ci się utworzyć swój gabinet',
    summary: 'Podsumowanie',
    privacyPolicyText:
      'Klikając "Otwórz DobryGabinet.com", potwierdzasz, że przeczytałeś i zgadzasz się z następującymi dokumentami DobregoGabinetu: ',
    privacyPolicy: 'Polityką prywatności',
    statute: 'Regulaminem',
    termsAndConditions: 'Regulamin',
    name: 'Dane',
    address: 'Adres',
    step: 'KROK',
    outOf: 'Z',
    headerText: 'Krok {step} z {total}'
  },
  address: {
    streetName: 'Ulica',
    houseNumber: 'Nr domu',
    number: 'Numer',
    apt: 'Nr mieszkania',
    city: 'Miasto',
    zipCode: 'Kod pocztowy',
    country: 'Kraj',
    county: 'Województwo',
    selectCountry: 'Wybierz kraj'
  },
  cron: {
    repeatEvery: 'Powtarzaj co',
    repeatOn: 'Powtarzaj w ',
    ends: 'Kończy się',
    never: 'nigdy',
    after: 'po',
    onDate: 'w dniu',
    week: 'tydzień | tygodnie',
    month: 'miesiąc | miesiące',
    occurrence: 'spotkaniu | spotkaniach',
    weekDays: {
      mo: 'PN',
      tu: 'WT',
      we: 'ŚR',
      th: 'CZ',
      fr: 'PT',
      sa: 'SB',
      su: 'ND',
      monday: 'Poniedziałek',
      tuesday: 'Wtorek',
      wednesday: 'Środa',
      thursday: 'Czwartek',
      friday: 'Piątek',
      saturday: 'Sobota',
      sunday: 'Niedziela'
    }
  },
  sidebar: {
    calendar: 'Kalendarz',
    patients: 'Pacjenci',
    services: 'Usługi',
    practice: 'Ośrodek / gabinet',
    employees: 'Pracownicy',
    settings: 'Ustawienia',
    account: 'Konto',
    sms: 'SMS',
    email: 'E-mail',
    reports: 'Raporty',
    invoices: 'Faktury',
    income: 'Przychód',
    subscription: 'Abonament',
    notifications: 'Powiadomienia',
    gdpr: 'RODO',
    onlineRegistration: 'Rejestracja online'
  },
  user: {
    myAccount: 'Moje konto',
    settings: 'Ustawienia',
    signOut: 'Wyloguj',
    profile: 'Profil',
    hereYouCanFindInfo: 'Tutaj możesz znaleźć informacje o swoim profilu',
    deleteAccount: 'Usuń konto',
    socialSignIn: 'Logowanie przez zewnętrzne konta',
    followingAccountsAreConnected:
      'Następujące zewnętrzne konta są podłączone do Twojego konta w celu logowania',
    connectedAccounts: 'Połączone konta',
    clickToConnect:
      'Kliknij na dany przycisk, aby połączyć lub rozłączyć logowanie z zewnętrznego konta',
    deleteByClicking:
      'Aby usunąć konto wyślij wiadomość e-mail do administrator.',
    dataWillBeDeleted:
      'Wszystkie twoje dane zostaną nieodwracalnie zniszczone.',
    accountCreatedOn: 'To konto zostało utworzone',
    connect: 'Połącz',
    disconnect: 'Rozłącz',
    contact: 'Kontakt'
  },
  subscription: {
    subscription: 'Abonament',
    programSubscription: 'Abonament DobregoGabinetu',
    hello: 'Witaj!',
    ourEmailAddress: 'kontakt@dobrygabinet.com',
    subscriptionExplanation:
      'Jeśli to Twój pierwszy raz tutaj, prawdopodobnie jesteś nowym użytkownikiem DobregoGabinetu. Mamy dla Ciebie świetną wiadomość - masz 14 dni całkowicie darmowego dostępu do programu zarówno w Twoim gabinecie, jak i ośrodku. To doskonały okres na zapoznanie się z funkcjonalnościami programu, przetestowanie różnych opcji i zadanie ewentualnych pytań. Jesteśmy tutaj, aby Ci pomóc!',
    subscriptionExplanation2:
      'Jeśli potrzebujesz naszej pomocy lub masz jakiekolwiek pytania, skontaktuj się z nami pod adresem:',
    subscriptionExplanation3: 'Nasz zespół chętnie odpowie na Twoje zapytania.',
    subscriptionExplanation4:
      'Po zakończeniu dwutygodniowego darmowego okresu testowego, jeśli zdecydujesz się kontynuować korzystanie z naszego programu, będziesz potrzebować miesięcznej licencji. Aktualny cennik jest dostępny na naszej stronie internetowej. Jeśli wybierzesz odpowiedni plan dla siebie, napisz do nas na podany powyżej adres e-mial z informacjami dotyczącymi wybranej licencji podając również dane do faktury (w tym nr NIP).\n' +
      '\n',
    subscriptionExplanation5:
      'Dziękujemy za zainteresowanie DobrymGabinetem i życzymy owocnej współpracy!',
    additionalQuestions:
      'Masz dodatkowe pytania? Poniżej znajdują się najczęstsze pytania i odpowiedzi.',
    howLongDoesTrialLast: 'Jak długo trwa okres próbny?',
    howLongIsTrialExplanation:
      'Okres próbny trwa 14 dni. Po tym czasie zapytamy Cię czy chciał/-abyś dalej używać programu.',
    howToPayForSubscription: 'Jak mogę zapłacić za abonament?',
    howToPayForSubscriptionAnswer:
      'Aby opłacić abonament, prosimy o przesłanie nam drogą mailową swoich danych do faktury oraz wybranego przez Ciebie pakietu.',
    howToGetInvoice: 'Kiedy i jak otrzymam fakturę?',
    howToGetInvoiceAnswer:
      'Fakturę za abonament prześlemy do Ciebie z początkiem nowego miesiąca. Faktura będzie miała 7-dniowy okres płatności. Jeśli Twój okres próbny skończy się np. w połowie miesiąca, wystawimy Ci fakturę proporcjonalną do pozostałych dni danego miesiąca.',
    whatHappensWhenIDontPay: 'Co się stanie jeśli nie opłacę faktury?',
    whatHappensWhenIDontPayAnswer:
      'Jeśli nie otrzymamy opłaty za fakturę w wyznaczonym okresie płatności, rozpoczniemy naliczanie odsetek za opóźnienie, które zostaną doliczone do następnej faktury.',
    iDontWantToContinue:
      'Nie chcę przedłużać korzystania z programu po okresie testowym. Co muszę zrobić?',
    iDontWantToContinueAnswer:
      'Jeśli nie zamierzasz kontynuować korzystania z systemu po zakończeniu okresu testowego, proszę napisz do nas maila z taką informacją.',
    howToCancel: 'Jak mogę zrezygnować z subskrypcji?',
    howToCancelAnswer:
      'Aby zrezygnować z działającej subskrypcji, prosimy o przesłanie do nas maila z wypowiedzeniem zgodnie z postanowieniami regulaminu. Okres wypowiedzenia wynosi 14 dni.',
    needMoreTime: 'Potrzebuję więcej czasu. Czy mogę przedłużyć okres próbny?',
    needMoreTimeAnswer:
      'Jeśli potrzebujesz dodatkowego czasu na przemyślenie programu, napisz do nas maila. Z przyjemnością rozważymy Twoją prośbę.',
    howToSignContract: 'Jak mogę podpisać umowę?',
    howToSignContractAnswer:
      'Dodatkowe podpisanie umowy nie jest konieczne, ponieważ przy zakładaniu konta użytkownik wyraża zgodę elektronicznie, co stanowi wiążącą umowę.',
    whatHappensToTrialDataIfIStay:
      'Czy dane wprowadzone w okresie próbnym pozostaną w programie gdy przejdę na abonament?',
    whatHappensToTrialDataIfIStayAnswer:
      'Tak, wszystkie dane wprowadzone do programu w okresie testowym pozostaną w nim i będą dostępne w tej samej formie w okresie abonamentowym.'
  },
  validators: {
    correctEmail: 'Podaj proszę poprawny adres e-mail',
    correctTime: 'Podaj poprawną godzinę',
    correctTimeRange: 'Podaj godzinę pomiędzy {start} i {end}',
    timeRangeOverlap: 'Podaj godziny które na siebie nie nachodzą',
    correctPhoneNumber: 'Podaj prawidłowy numer telefonu',
    correctTimeOrder: 'Czas początkowy musi być mniejszy niż czas końcowy',
    correctDuration: 'Podaj poprawny czas trwania: np. 1:20h or 15min',
    invalidDate: 'Podaj proszę poprawną datę',
    invalidDateFuture:
      'Podaj proszę poprawną datę, która nie jest w przyszłości',
    invalidDatePast: 'Podaj proszę poprawną datę, która nie jest w przeszłości',
    correctPrice: 'Podaj proszę poprawną cenę',
    correctSubdomain: 'Podaj poprawną subdomenę',
    isRequired: 'jest wymagane',
    tooLong: 'jest za długie (maks:',
    breakTooLarge:
      'Przerwa jest zbyt długa! Maksymalna długość przerwy to 1 440 minuty (24 godziny).',
    incorrectPesel: 'Nieprawidłowy numer PESEL'
  },
  toastsTitle: {
    success: 'Udało się',
    error: 'Błąd',
    warning: 'Uwaga',
    info: 'Info'
  },
  therapists: {
    acronym: 'W',
    all: 'Wszyscy',
    therapists: 'terapeuci',
    title: 'Lista terapeutów',
    noItems: 'Nie znaleziono terapeutów',
    addNew: 'Dodaj nowego pracownika',
    close: 'Zamknij',
    search: 'Wyszukaj terapeutę',
    counselor: 'Terapeuta',
    addToList: 'Zaznacz aby dodać do listy',
    searchTherapist: 'Wyszukaj terapeutę',
    pressMetaKeyToSelectMultiple:
      'Aby wybrać kilku terapeutów na raz, przytrzymaj ctrl lub cmd.',
    favoriteTherapistsTitle: 'Wybierz ulubionych terapeutów'
  }
}
