import { CalendarState } from '../types/calendar'

export const state = (): CalendarState => ({
  startDate: null,
  startDateExpiration: null
})

export const mutations = {
  setStartDate(state: CalendarState, startDate: Date) {
    state.startDate = startDate
    state.startDateExpiration = new Date().getTime() + 30 * 60 * 1000
  }
}

export const getters = {
  getStartDate: (state: CalendarState) => {
    const expirationTime = state.startDateExpiration
    if (expirationTime && new Date().getTime() > expirationTime) {
      state.startDate = null
      state.startDateExpiration = null
      return null
    }

    return state.startDate
  }
}
