module.exports = {
  // markServicesAsAvailable:
  //   'Aby twoje usługi były widoczne dla potencjalnych pacjentów, oznacz je jako widoczne w rejestracji online.',
  meetingHasBeenCreated: 'Spotkanie zostało utworzone',
  meetingHasBeenUpdated: 'Spotkanie zostało zaktualizowane',
  meetingHasBeenRejected: 'Spotkanie zostało odrzucone',
  meetingHasBeenDeleted: 'Spotkanie zostało usunięte',
  clickHereToGoToServices: 'Kliknij tutaj, aby przejść do widoku usług.',
  clickToCopy: 'Kliknij by skopiować link',
  iFrameTextCopied:
    'Kod formularza skopiowany! Wklej go teraz na swoją stronę.',
  iFrameSourceCodeDescription:
    'Chcesz dodać formularz do swojej strony internetowej? Kliknij poniższy tekst, aby go skopiować, a następnie wklej go w kod swojej strony.',
  createMeeting: 'Potwierdź',
  updateMeeting: 'Potwierdź prośbę',
  createNewPatient: 'Utwórz nowego pacjenta',
  meetingRequest: 'Rezerwacja spotkania',
  newDate: 'Nowa data',
  oldDate: 'Stara data',
  meetingRequestUpdate: 'Pacjent prosi o zmianę spotkania',
  meetingRequestDetails: 'Szczegóły spotkania',
  contactWithPatient:
    'Skontaktuj się z pacjentem, aby umówić nowe spotkanie. Po odrzuceniu rezerwacji, dane pacjenta znajdziesz w tabeli rezerwacji. Możesz również włączyć płatności online, które automatycznie potwierdzają spotkanie, gwarantując dostępność terapeuty.',
  meetingRequests: 'Rezerwacje do potwierdzenia',
  minutesBetweenMeetings: 'Liczba minut przerwy między spotkaniami',
  minutesBetweenMeetingsDescription:
    'Ta liczba minut zostanie doliczona po spotkaniu zanim kolejne spotkanie będzie możliwe ',
  sendReceptionNewMeetingRequestEmail:
    'Wysyłaj maila o nowym spotkaniu również do recepcji',
  sendReceptionNewMeetingRequestEmailDescription:
    'Zdecyduj, czy każdy pracownik z rolą recepcja ma otrzymać powiadomienie mailowe o nowej prośbie o spotkanie',
  sendAdminNewMeetingRequestEmail:
    'Wysyłaj maila o nowym spotkaniu również do administratorów',
  sendAdminNewMeetingRequestEmailDescription:
    'Zdecyduj, czy każdy pracownik z rolą administrator ma otrzymać powiadomienie mailowe o nowej prośbie o spotkanie',
  sendReceptionNewMeetingRequestMobileNotification:
    'Wysyłaj powiadomienie o nowym spotkaniu również do recepcji',
  sendReceptionNewMeetingRequestMobileNotificationDescription:
    'Po włączeniu tej opcji pracownicy recepcji otrzymają powiadomienia w swojej aplikacji mobilnej o nowych prośbach o spotkanie',
  sendAdminNewMeetingRequestMobileNotification:
    'Wysyłaj powiadomienie o nowym spotkaniu również do administratorów',
  sendAdminNewMeetingRequestMobileNotificationDescription:
    'Po włączeniu tej opcji pracownicy administracji otrzymają powiadomienia w swojej aplikacji mobilnej o nowych prośbach o spotkanie',
  automaticConfirmNewMeetingRequest:
    'Automatycznie akceptuj prośby o spotkania, gdy płatność jest realizowana w gabinecie',
  automaticConfirmNewMeetingRequestDescription:
    'Wybierz tę opcję, aby prośby o spotkania były automatycznie akceptowane',
  noMeetingRequests:
    'Aktualnie nie masz nowych spotkań do potwierdzenia. Kiedy pacjenci będą chcieli umówić się na wizytę, ich prośby pojawią się tutaj.',
  noMeetingRequestsHeader: 'Brak nowych spotkań do potwierdzenia.',
  patientPortalTitle: 'Ustawienia Portalu Pacjenta',
  patientPortalDescription:
    'Skonfiguruj w jaki sposób Twoi pacjenci widzą i mogą zmieniać prośby o spotkania',
  automaticChangeTimeConfirmByPatient:
    'Automatycznie akceptuj prośby pacjenta o zmianę terminu',
  automaticChangeTimeConfirmByPatientDescription:
    'Zdeifniuj czy prośby o zmianę terminu juz utworzonego spotkania będą automatycznie akceptowane. Jeśli nie, przyjdzie prośba o zmianę temrinu',
  przelewy24ConfigUpdated: 'Konfiguracja Przelewów24 została zaktualizowana',
  patient: 'Pacjent',
  pickRoom: 'Wybierz pokój',
  practiceSlug: 'Zmiana adresu Twojego formularza',
  canChangeThisSlug:
    'Możesz zmienić końcówkę adresu formularza jeśli jest ona dostępna i pasuje do Twojego gabinetu',
  privacyPolicy: 'Link do Polityki Prywatności',
  registrationEnabled: 'Status rejestracji online',
  registrationInfo:
    'Po włączeniu rejestracji online Twoi pacjenci będą mogli umawiać się na spotkania z Tobą przez formularz dostępny online. Wybierz również usługi dostępne do rejestracji online oznaczając je odpowiednio w ',
  rememberToSetWorkingTimes:
    'Pamiętaj o określeniu czasu pracy dla każdej osoby, która ma być dostępna w rejestracji online. Więcej informacji znajdziesz w ',
  serviceSettings: 'ustawieniach usług.',
  ourInstructions: 'naszej instrukcji.',
  registrationLink: 'Link do rejestracji',
  room: 'Pokój',
  sureToConfirmMeeting: 'Czy potwierdzasz spotkanie?',
  sureToConfirmUpdateMeeting:
    'Czy potwierdzasz prośbę pacjenta o zmianę spotkania:',
  termsOfUse: 'Link do Regulaminu',
  thereAreSimilarPatients:
    'Pacjent z tymi danymi już istnieje w Twojej placówce. Automatycznie wybraliśmy istniejącego pacjenta. Prosimy zweryfikować to poniżej lub wybrać opcję utworzenia nowego pacjenta.',
  title: 'Rejestracja online',
  configUpdated: 'Ustawienia rejestracji online zostały zaktualizowane',
  enabled: 'Rejestracja online jest włączona',
  disabled: 'Rejestracja online jest wyłączona',
  registrationUrl: 'Adres Twojego formularza rejestracji dla pacjentów',
  maximumMeetingRequestCancellationTime:
    'Maksymalny czas na odwołanie spotkania',
  maximumMeetingRequestChangeTime: 'Maksymalny czas zmiany terminu spotkania',
  maximumMeetingRequestChangeTimeDescription:
    'Określ ile czasu przed spotkaniem Twoi klienci mogą zmienić jego datę lub godzinę',
  maximumMeetingRequestCancellationTimeDescription:
    'Określ ile czasu przed spotkaniem Twoi klienci mogą je odwołać. W przypadku płatność Przelewy24 zostaną im również zwrócone pieniądze',
  isCancelledMeetingFreeSlot:
    'Czy odwołane spotkanie powinno być ponownie udostępnione jako dostępny termin w systemie rejestracji online dla innych pacjentów?',
  isCancelledMeetingFreeSlotBottomText:
    'Przy wybraniu „Nie” kalendarz wolnych terminów nie pokaże terminów odwołanych spotkań dla nowych pacjentów.',
  isCancelledMeetingFreeSlotTitle: 'Ustawienia odwołań',
  isCancelledMeetingFreeSlotLeftDescription:
    'Określ jak system ma postępować w przypadku odwołanych spotkań.',
  clickToCopyRegistrationUrl:
    'Kliknij, aby otworzyć Twój formularz rejestracji. Jeśli nie widzisz terminów zajrzyj do ',
  onlineRegistrationStatusTitle: 'Status rejestracji online',
  onlineRegistrationStatusDescription:
    'Tutaj możesz ustawić czy i pod jakim adresem będziesz udostępniać rejestrację online.',
  timeSettingsTitle: 'Ustawienia czasu',
  timeSettingsDescription:
    'Tutaj możesz określić wyprzedzenie przed i po spotkaniu ',
  notificationSettingsTitle: 'Powiadomienia o nowych spotkaniach',
  notificationSettingsDescription:
    'Ustaw w jaki sposób mają być wysyłane powiadomienia o nowych spotkaniach',
  termsSettingsTitle: 'Ustawienia regulaminów',
  termsSettingsDescription:
    'Tutaj możesz wkleić link do regulaminu rejestracji online i Twojej Polityki Prywatności, które zobaczą klienci w rejestracji online. ',
  parseTermsOfUseLinkHere:
    'Wklej tutaj link regulaminu rejestracji online, który jest dostępny na Twojej stronie. ',
  parsePrivacyPolicyLinkHere:
    'Wklej tutaj link do Twojej Polityki Prywatności, która jest dostępna na Twojej stronie. ',
  registrationLinkCopied: 'Skopiowano link do rejestracji online',
  minimumPrecedingMinutes: 'Minimalne wyprzedzenie przed spotkaniem',
  minimumPrecedingMinutesDescription:
    'Minimalne wyprzedzenie z jakim pacjenci mogą się rejestrować. Np. jeśli wyprzedzenie wynosi 8h to pacjenci będą mogli się umówić dopiero za 8 godzin licząc od aktualnej godziny. Dla jednego i dwóch dni, będą pokazywały się terminy zaczynające się od rana.',
  maximumPrecedingMinutes: 'Maksymalne wyprzedzenie dla spotkań',
  maximumPrecedingMinutesDescription:
    'Określ maksymalny dostępny okres, na jaki pacjenci mogą umawiać z wyprzedzeniem spotkania.',
  exactSlot: 'Zaokrąglij początek nowych spotkania',
  exactSlotDescription:
    'Proponuj nowe spotkania z zaokrągleniem do podanej wartości',
  roundSlotTimeTo: 'Proponuj nowe spotkanie z odstępami co',
  roundToDescription:
    'Ta liczba minut ustala momenty rozpoczęcia potencjalnych spotkań. Uwzględnia godziny prac terapeutów i zapisane spotkania w kalendarzu.',
  quarter: 'Kwadrans',
  preciseSearch: 'Szukanie precyzyjne',
  preciseSearchDescription:
    'Opcja znajduje maksymalną liczbę wolnych terminów w kalendarzu poprzez wyłączenie zaokrągleń w niektórych przypadkach. Może skutkować mniej standardowymi godzinami np. 10:47 zamiast 10:50',
  halfHour: 'Pół godziny',
  hour: 'Godzina',
  none: 'Brak',
  twoHours: 'Dwie godziny',
  threeHours: 'Trzy godziny',
  fourHours: 'Four hours',
  fiveHours: 'Five hours',
  details: 'Szczegóły',
  reject: 'Odrzuć',
  sureToRejectMeeting: 'Czy na pewno chcesz odrzucić to spotkanie?',
  sendSMSUponRejectMeeting: 'Wyślij SMS do pacjenta o odwołaniu spotkania',
  rejectButtonInfo:
    'Po odrzuceniu spotkania pacjent otrzyma powiadomienie. Możesz się z nim skontaktować, aby ustalić nowy termin',
  createdAt: 'Prośba z dnia',
  confirmInfo:
    'Po zaakceptowaniu spotkania, zostanie ono automatycznie dodane do Twojego kalendarza, a pacjent otrzyma potwierdzenie.',
  confirmUpdateInfo:
    'Po potwierdzeniu prośby termin wizyty zostanie zmieniony, a pacjent otrzyma powiadomienie o nowej dacie.',
  rejectUpdateInfo:
    'Pacjent poprosił o zmianę terminu wizyty. Jeśli odrzucisz tę prośbę, pozostanie ona na pierwotnej dacie. Pacjent może również anulować wizytę.',

  day: '{count} dzień |{count} dni',
  patientNote: 'Wiadomość od pacjenta',

  // Registration Form Customization Settings
  logoSet: 'Logo ustawione',
  logoRegistrationUpload: 'Logo formularza',
  logoRegistrationUploadDescription:
    'Logo widoczne na formularzu. Jest to to samo logo widoczne w ustawieniach.',

  formColors: 'Kolory formularza',
  formColorsDescription:
    'Dopasuj kolory Twojego formularza. Możesz wybrać kolor podstawowy i dodatkowy.',
  primaryColor: 'Kolor podstawowy',
  primaryColorDescription:
    'Wybrany kolor będzie używany jako akcent w Twoim formularzu rejestracji online.',
  secondaryColor: 'Kolor tła',
  secondaryColorDescription:
    'Kolor dodatkowy będzie używany jako kolor tła. Jeśli go nie ustawisz wybierzemy kolor na podstawie koloru podstawowego.',

  formHeader: 'Nagłówek formularza',
  formHeaderDescription:
    'Zdecyduj, czy nagłówek ma być widoczny w formularzu. Wyłączenie nagłówka sprawia, że formularz wygląda lepiej, gdy jest osadzony na Twojej stronie internetowej.',
  displayHeader: 'Wyświetl nagłówek',
  displayHeaderDescription:
    'Po wyłączeniu, nagłówek z logiem i adresem w formularzu rejestracji online nie będzie pokazywany.',

  customizationSettingsSaved: 'Ustawienia formularza zostały zapisane',

  tabs: {
    reservations: 'Rezerwacje',
    settings: 'Ustawienia',
    payments: 'Płatności',
    registration: 'Rejestracja w Przelewy24',
    form: 'Formularz'
  },

  // Maximum Proceeding Options
  oneWeek: 'Jeden tydzień',
  twoWeeks: 'Dwa tygodnie',
  threeWeeks: 'Trzy tygodnie',
  fourWeeks: 'Cztery tygodnie',
  fiveWeeks: 'Pięć tygodni',
  sixWeeks: 'Sześć tygodni',
  sevenWeeks: 'Siedem tygodni',
  eightWeeks: 'Osiem tygodni',
  nineWeeks: 'Dziewięć tygodni',
  tenWeeks: 'Dziesięć tygodni',
  elevenWeeks: 'Jedenaście tygodni',
  twelveWeeks: 'Dwanascie tygodni'
}
