import { useStore, computed } from '@nuxtjs/composition-api'

export const useActiveFavoriteTherapistsStore = () => {
  const store = useStore()

  const activeFavoriteTherapists = computed<number[]>(
    () => store.getters['activeFavoriteTherapists/getActiveFavoriteTherapists']
  )
  const setActiveFavoriteTherapists = (therapistIds: number[]) => {
    store.commit(
      'activeFavoriteTherapists/setActiveFavoriteTherapists',
      therapistIds
    )
  }

  return {
    activeFavoriteTherapists,
    setActiveFavoriteTherapists
  }
}
